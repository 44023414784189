// setTimeout(function(){
//   $(".mv8_wrap").addClass('step1');
// },500);

$(document).ready(function(){
  var $slider  = $('.mv');
  var $slide   = $slider.children();
  var slideLen = $slide.length;
  $slider.slick({
    dots: false,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 3500,
    fade: true,
    arrows: false,
    loop: false,
    dots: false,
    infinite: false,
    pauseOnFocus: false,
    pauseOnHover:false,
    swipe: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  })
  .on('afterChange', function() {
    var $self = $(this);
    if((slideLen - 1) <= $self.slick('slickCurrentSlide')){
      $self.slick('slickSetOption', 'autoplay', false);
    }
    if ($('.mv3_wrap').hasClass('slick-current')) {
      setTimeout(function(){
        $(".mv3_tex").addClass('step1');
      },1000);
    }
    if ($('.mv_wrap').hasClass('slick-current')) {
      setTimeout(function(){
        $(".plant01").addClass('plant_mov1');
      },100);
      setTimeout(function(){
        $(".mvp1").addClass('step2');
      },400);
      setTimeout(function(){
        $(".mvp2").addClass('step3');
      },2300);
      setTimeout(function(){
        $(".mvp3,.mvp4,.doop_tex_img").addClass('step4');
      },3300);
      setTimeout(function(){
        $(".mv_wrap").addClass('step6');
      },3300);
      setTimeout(function(){
        $(".mvtex_r").addClass('step5');
      },4500);
      setTimeout(function(){
        $(".plant02").addClass('plant_mov1');
      },3500);
    }
  });
  $(function(){
    if ($('.mv2_wrap').hasClass('slick-current')) {
      setTimeout(function(){
        $(".sl_abbox").addClass('step1');
      },1000);
    }
  });
  // $(function(){
  //   if ($('.mv2_wrap').hasClass('slick-current')) {
  //     setTimeout(function(){
  //       $(".mv2_tex").addClass('step1');
  //     },1000);
  //   }
  // });
});
